import React, { useEffect, useState } from 'react';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { UserListItem } from 'src/app/redux/state/event-calendar/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { EventCreationHeader } from '../../../../../EventCreationHeader/EventCreationHeader';
import { AddUserMCContainer } from '../AddUserMCContainer';
import { UsersListContainer } from '../UsersListContainer';
import s from './AddUsersToEvent.module.scss';

interface Props {
	formName: string;
	onSubmitAfterValidation: () => void;
	initialUsersIds: string[];
}

export const AddUsersToEvent: React.FC<Props> = props => {
	const {
		formName, //
		onSubmitAfterValidation,
		initialUsersIds,
	} = props;

	// * API
	const { data, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const allCompanyUsers = data?.body ?? [];

	// - Выбираем только ту информацию которая нужна для списков пользователей.
	const allCompanyUsersList: UserListItem[] = allCompanyUsers.map(item => ({
		id: item.user.id,
		name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
		avatar: item.user.avatar,
		department: item.departmentUser?.department.name,
		position: '',
	}));

	// * Selectors
	const selectedUsersInfo = useAppSelector(state => state.event_calendar.event_form.selectedUsersInfo);

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo } = actionsEventCalendar;

	// - Только редактирования. Добавляем пользователей в состояние формы.
	useEffect(() => {
		if (initialUsersIds.length && allCompanyUsers.length) {
			dispatch(setSelectedUsersInfo(allCompanyUsersList.filter(user => initialUsersIds.find(id => user.id === id))));
		}
	}, [allCompanyUsers.length]);

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (selectedUsersInfo.users.length) {
			onSubmitAfterValidation();
		}
	};

	// * Render
	return (
		<>
			{showModal && ( // ! Для очистки выбранных пользователей. Можно воспользоваться useImperativeHandle для доступа к setTempSelectedUsersIds.
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<AddUserMCContainer
						toggleModal={toggleModal}
						allUsers={allCompanyUsersList}
					/>
				</ModalNewProto>
			)}

			<form
				id={formName}
				onSubmit={onSubmit}
			/>
			<div className={s.header}>
				<EventCreationHeader title="Участники" />
			</div>

			<SelectedCount
				className={s.userNumber}
				title="Всего участников"
				count={selectedUsersInfo.users.length ?? 0}
			/>

			<div className={s.addButton}>
				<AddButton
					title="Добавить участника"
					onClick={toggleModal}
				/>
			</div>

			{selectedUsersInfo.users.length > 0 && <UsersListContainer users={selectedUsersInfo.users} />}
		</>
	);
};
