import { DateTime } from 'luxon';

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
export const COMPANY_ID_DEV = 'dc0a6990-2f76-488e-a7b9-e52d37797429'; //!DEV: id Ланит-Терком
export const COMPANY_ID_PREPROD = 'FD0BB240-FC50-463D-BE32-01EC6D0A48A0'; //!PREPROD: id Ланит-Терком
export const COMPANY_ID_PROD = 'fd0bb240-fc50-463d-be32-01ec6d0a48a0'; //!PROD: id Ланит-Терком
export const COLORS = [
	'var(--color-1)',
	'var(--color-2)',
	'var(--color-3)',
	'var(--color-4)',
	'var(--color-5)',
	'var(--color-6)',
	'var(--color-7)',
	'var(--color-8)',
	'var(--color-9)',
	'var(--color-10)',
	'var(--color-11)',
	'var(--color-12)',
];

export const WORKDAY_HOURS = 8;
export const MOSCOW_TIME_ZONE = 'Europe/Moscow';
export const LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO = 10;

// TODO: В идеале должна быть дата начала работы сотрудника в компании, пока что задана та дата которая была в Angular основанная на ответе на ответе запрос https://dev.digital-office.dso.lanit-tercom.com/gateway/GraphicalUserInterface/ (createdAtUtc)
export const MIN_APP_DATE = DateTime.fromObject({ year: 2022, month: 9 }).startOf('month').startOf('day').toJSDate();
export const MAX_APP_DATE = DateTime.fromJSDate(new Date()).plus({ month: 1 }).startOf('month').startOf('day').toJSDate();
