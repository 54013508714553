import React, { useEffect, useState } from 'react';
import s from './OfficesPageHeader.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { OfficeFormMC } from '../OfficeFormMC/OfficeFormMC';
import { useAppDispatch } from 'src/app/redux/utils';
import { companyServiceActions } from 'src/app/redux/state/company/actions';
import { useRights } from 'src/shared/hooks/useRights';

export const OfficesPageHeader: React.FC = () => {
	// * Rights
	const isAdmin = useRights();

	// * Actions
	const dispatch = useAppDispatch();
	const { getCompany } = companyServiceActions.company.async;

	// * Modal
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => {
		setShowModal(prevState => !prevState);
	};

	// * Initialise
	useEffect(() => {
		dispatch(
			getCompany({
				params: {
					includeOffices: false,
				},
			}),
		);
	}, []);

	// * Render
	return (
		<div className={s.container}>
			{showModal && (
				<Modal
					show={showModal}
					toggleModal={toggleModal}
					zIndex={3}
					Content={<OfficeFormMC toggleModal={toggleModal} />}
				/>
			)}

			<h1>Офисы</h1>

			{isAdmin && <Button onClick={toggleModal}>Создать офис</Button>}
		</div>
	);
};
