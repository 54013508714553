import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { CommunicationInfo } from 'src/app/redux/queries/user-service/types/types';
import { RootState } from 'src/app/redux/store';
import { useAppDispatch } from 'src/app/redux/utils';
import { UserContact } from 'src/entities/UserContact/UserContact';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { ReactComponent as CopySVG } from 'src/shared/assets/svg/action/copy.svg';
import { ReactComponent as StarSVG } from 'src/shared/assets/svg/action/star.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { CONTACTS } from '../../const/contacts';
import { ContactDropDown } from '../ContactDropDown/ContactDropDown';
import { ContactForm } from '../ContactForm/ContactForm';
import s from './Contacts.module.scss';

interface Props {
	userId: string;
	contacts: CommunicationInfo[];
}

export const Contacts: FC<Props> = props => {
	const { userId, contacts } = props;

	// * Rights
	const hasEditUsersRights = useRights(1);

	const personalInfo = useSelector((state: RootState) => state.user_service.user.userInfo);
	const isPersonalCard = personalInfo?.user.id === userId;

	const hasRights = hasEditUsersRights || isPersonalCard;

	// * Modal
	const [contactModal, setContactModal] = useState(false);
	const toggleContactModal = () => setContactModal(prevState => !prevState);

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	const urlToClipboard = (value: string) => {
		navigator.clipboard.writeText(value).then(() =>
			dispatch(
				addNotification({
					type: 'info',
					message: 'Контакт скопирован в буфер обмена',
				}),
			),
		);
	};

	// * Contacts
	const getContacts = () => {
		// const sortedContacts = [...contacts].sort(contact1 => (contact1.type === 'BaseEmail' ? -1 : 1));
		const baseEmail = contacts.filter(contact => contact.type === 'BaseEmail');
		const email = contacts.filter(contact => contact.type === 'Email');
		const otherContacts = contacts.filter(contact => contact.type !== 'BaseEmail' && contact.type !== 'Email');
		const sortedContacts = [...baseEmail, ...email, ...otherContacts];

		const hasEmail = sortedContacts.filter(contact => contact.type === 'Email').length > 0;

		return sortedContacts.map(contact => (
			<div
				key={contact.id}
				className={s.content}
			>
				<div className={s.label}>
					<span>{CONTACTS[contact.type]}</span>
					{contact.type === 'BaseEmail' && hasEmail && <StarSVG />}
				</div>

				<div className={s.wrapper}>
					<UserContact
						type={contact.type}
						value={contact.value}
					/>

					<div className={s.actions}>
						<IconButton
							Icon={<CopySVG />}
							onClick={() => urlToClipboard(contact.value)}
						/>

						{contact.type !== 'BaseEmail' && hasRights && (
							<ContactDropDown
								value={contact.value}
								type={contact.type}
								contactId={contact.id}
								isConfirmed={contact.isConfirmed}
							/>
						)}
					</div>
				</div>

				{(contact.type === 'BaseEmail' || contact.type === 'Email') && !contact.isConfirmed && (
					<span className={s.warning}>Требует подтверждения</span> //
				)}
			</div>
		));
	};

	// * Render
	return (
		<>
			{contactModal && (
				<ModalNewProto
					isOpen={contactModal}
					onClose={toggleContactModal}
				>
					<ContactForm onClose={toggleContactModal} />
				</ModalNewProto>
			)}

			<div className={s.container}>
				<div className={s.wrapper}>
					<h4>Контакты</h4>

					{hasRights && (
						<IconButton
							Icon={<AddSVG />}
							onClick={toggleContactModal}
						/>
					)}
				</div>

				{getContacts()}
			</div>
		</>
	);
};
