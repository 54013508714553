import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useGetBookingFindQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { GetRoomRES, Room } from 'src/app/redux/state/office/room/types';
import { WorkSpaceInfo } from 'src/app/redux/state/office/workspace/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ReactComponent as Right } from 'src/shared/assets/svg/navigation/Arrow_right_v1.svg';
import { dateToFullRuDateString, injectTimeToDate } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { BookingInfoCardData } from '../../BookingInfoCard/types';
import s from './MyBookingCard.module.scss';
import { AllBookingsMC } from './modal_content/AllBookingsMC';

export const MyBookingCard: React.FC = () => {
	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);
	const officeList = useAppSelector(state => state.office_service.office.officesList);

	// * Actions
	const dispatch = useAppDispatch();
	const { findBooking } = officeServiceActions.booking.async;
	const { getRoom, findRooms } = officeServiceActions.room.async;

	const [allRooms, setAllRooms] = useState<Room[]>([]);
	const [allWorkspaces, setAllWorkspaces] = useState<WorkSpaceInfo[]>([]);

	// * API
	const { currentData: bookingData } = useGetBookingFindQuery({
		skipcount: 0,
		takecount: 1000,
		upcomingOnly: false,
		userId,
		includeBans: false,
	});

	// * Initialize
	useEffect(() => {
		dispatch(
			findRooms({
				params: { skipCount: 0, takeCount: 1000 },
			}),
		)
			.unwrap()
			.then(res => {
				const promises = res.body.map(room => {
					return dispatch(
						getRoom({
							params: { roomId: room.id },
						}),
					).unwrap();
				});

				Promise.allSettled(promises)
					.then(res => {
						const values: GetRoomRES[] = res?.filter(res => res.status === 'fulfilled')?.map((res: any) => res.value);
						const allRooms: Room[] = [];
						const allWorkspaces: WorkSpaceInfo[] = [];
						values.forEach(value => {
							allRooms.push(value.room);
							value.workspaces.forEach(workspace => allWorkspaces.push(workspace));
						});

						setAllRooms(allRooms);
						setAllWorkspaces(allWorkspaces);
					})
					.catch(error => console.log(error));
			})
			.catch(error => console.log(error));
	}, []);

	// TODO params
	useEffect(() => {
		dispatch(
			findBooking({
				params: {
					skipCount: 0,
					takeCount: 1000,
				},
			}),
		);
	}, []);

	// TODO: make all booking cards props generated from all workspaces and all rooms
	const allRoomsBookingInfo: Array<BookingInfoCardData & { timeForSortStart: string; timeForSortEnd: string }> = [];
	const allWorkspacesBookingInfo: Array<BookingInfoCardData & { timeForSortStart: string; timeForSortEnd: string }> = [];

	allRooms?.filter(room =>
		bookingData?.body?.forEach(booking => {
			if (booking.parentId === room.id) {
				const { id, startTime, endTime, user } = booking;
				const address = officeList?.find(office => office.id === room.officeId)?.address;

				address &&
					allRoomsBookingInfo.push({
						bookingId: id,
						date: new Date(startTime),
						startDate: new Date(startTime),
						// endDate: endTime ? new Date(endTime) : null,
						startTime: DateTime.fromISO(startTime).toLocaleString({ hour: '2-digit', minute: '2-digit' }),
						endTime: endTime ? DateTime.fromISO(endTime).toLocaleString({ hour: '2-digit', minute: '2-digit' }) : null,
						userName: `${user.firstName} ${user.lastName}`,
						address,
						roomName: room.name,
						timeForSortStart: startTime,
						timeForSortEnd: endTime ?? '',
						userId,
					});
			}
		}),
	);

	allWorkspaces?.filter(workspace =>
		bookingData?.body?.forEach(booking => {
			if (booking.parentId === workspace.id) {
				const { id, startTime, endTime, user } = booking;
				const room = allRooms.find(room => room.id === workspace.roomId);
				const address = officeList?.find(office => office.id === room?.officeId)?.address;

				// если startTime === '00:00', то ставим время помещения
				const startTimeBooking = DateTime.fromISO(startTime).toLocaleString({ hour: '2-digit', minute: '2-digit' });
				const startTimeFormatDate = injectTimeToDate(new Date(startTime), `${room?.roomType.startTime}`);
				const startTimeWorkspace = DateTime.fromJSDate(startTimeFormatDate).toLocaleString({
					hour: '2-digit',
					minute: '2-digit',
				});

				// если endTime === '00:00', то ставим время помещения
				const endTimeBooking = endTime ? DateTime.fromISO(endTime).toLocaleString({ hour: '2-digit', minute: '2-digit' }) : null;
				const endTimeFormatDate = endTime ? injectTimeToDate(new Date(endTime), `${room?.roomType.endTime}`) : null;
				const endTimeWorkspace = endTimeFormatDate
					? DateTime.fromJSDate(endTimeFormatDate).toLocaleString({
							hour: '2-digit',
							minute: '2-digit',
						})
					: null;

				room &&
					address &&
					allWorkspacesBookingInfo.push({
						bookingId: id,
						date: new Date(startTime),
						startDate: new Date(startTime),
						endDate: endTime ? new Date(endTime) : null,
						startTime: startTimeBooking === '00:00' ? startTimeWorkspace : startTimeBooking,
						endTime: endTime ? (endTimeBooking === '00:00' ? endTimeWorkspace : endTimeBooking) : null,
						userName: `${user.firstName} ${user.lastName}`,
						address,
						roomName: room?.name,
						workspaceName: workspace.name,
						timeForSortStart: startTime,
						timeForSortEnd: endTime ?? '',
						userId,
					});
			}
		}),
	);

	const allBookingInfo = [...allRoomsBookingInfo, ...allWorkspacesBookingInfo];

	const relevantBookingInfo = allBookingInfo.filter(booking => booking.timeForSortEnd === '' || new Date(booking.timeForSortEnd).getTime() > new Date().getTime()); // Убираем брони из прошлого.

	const orderedAllBookingInfo = [...relevantBookingInfo].sort((a, b) => {
		return Date.parse(a.timeForSortStart) - Date.parse(b.timeForSortEnd);
	});

	const onlyTemporaryBooking = orderedAllBookingInfo.filter(booking => booking.endTime);

	// * All booking
	const [showAllBooking, setShowAllBooking] = useState(false);
	const toggleShowAllBooking = () => setShowAllBooking(prevState => !prevState);

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={showAllBooking}
				onClose={toggleShowAllBooking}
				width="l"
			>
				<AllBookingsMC orderedAllBookingInfo={orderedAllBookingInfo} />
			</ModalNewProto>

			<div className={s.container}>
				<div className={s.header}>
					<h3>Мои брони</h3>

					<Button
						RightIcon={<Right />}
						variant="tertiary"
						onClick={toggleShowAllBooking}
					>
						Все брони
					</Button>
				</div>

				<div>
					{onlyTemporaryBooking && onlyTemporaryBooking.length > 0 ? (
						// ? <BookingInfoCard  {...onlyTemporaryBooking[0]}/>
						<div className={s.bookingInfo}>
							<div className={s.bookingInfo__dateTime}>
								<span>{dateToFullRuDateString(onlyTemporaryBooking[0].date)}</span>
								<span className={s.bookingInfo__time}>
									{onlyTemporaryBooking[0].startTime}-{onlyTemporaryBooking[0].endTime}
								</span>
							</div>

							<div>{onlyTemporaryBooking[0].address}</div>

							<div>
								<span>{onlyTemporaryBooking[0].roomName}</span>
								{onlyTemporaryBooking[0].workspaceName && <span>, место {onlyTemporaryBooking[0].workspaceName}</span>}
							</div>
						</div>
					) : (
						<div className={s.empty_text}>Нет броней в ближайшие дни</div>
					)}
				</div>
			</div>
		</>
	);
};
