import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { COLUMNS_WORK_TIME } from 'src/pages/TimeTrackingPage/ui/TimeLog/consts/COLUMNS_WORK_TIME';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { ReactComponent as InfoSVG } from 'src/shared/assets/svg/alerts/info.svg';
import { backendISOUTCToLocalLuxon, isoToDateString } from 'src/shared/lib/date';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { TableWithComments } from 'src/shared/ui/_tables/TableWithComments';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { Heading } from 'src/shared/ui/Heading';
import { TimeEnter } from 'src/widgets/TimeEnter';
import { TimeEditProps } from 'src/widgets/TimeEnter/ui/TimeEnter';
import { OTHER_PROJECT_NAME } from '../../consts/consts';
import { WorkTimeTableDataItem } from './_types';
import s from './TimeLog.module.scss';
import { useWorkTimesInfoContext } from 'src/app/contexts/useWorkTimesInfoContext';

interface Props {
	userId: string;
	editingDisabled?: boolean;
}

export const TimeLog: React.FC<Props> = props => {
	const { userId, editingDisabled } = props;

	// * Context
	const { workTimeInfo } = useWorkTimesInfoContext();

	// * Table data
	const workTimeTableData: WorkTimeTableDataItem[] = workTimeInfo.map(workTime => {
		const {
			project, //
			month,
			year,

			userHours,
			modifiedByUserAtUtc,
			description, // user description

			managerHours,
			modifiedByManagerAtUtc,
			managerDescription,
			manager,
		} = workTime;

		const initialDate = DateTime.fromObject({ month, year }).toJSDate();

		let activeDate;
		let activeDescription = description;

		if (modifiedByManagerAtUtc) {
			activeDate = isoToDateString(backendISOUTCToLocalLuxon(modifiedByManagerAtUtc).toISO() as string);
			activeDescription = managerDescription;
		} else if (modifiedByUserAtUtc) {
			activeDate = isoToDateString(backendISOUTCToLocalLuxon(modifiedByUserAtUtc).toISO() as string);
			activeDescription = description;
		}

		const projectInfo = {
			name: project.name ?? OTHER_PROJECT_NAME,
			userHours: (modifiedByManagerAtUtc ? managerHours : userHours) ?? 0,
			date: activeDate,
			description: activeDescription,
		};

		return {
			...projectInfo,
			extra: (
				<div className={s.tableButtons}>
					{modifiedByManagerAtUtc && (
						<Tooltip text={`Отредактировано: ${manager?.firstName} ${manager?.lastName}`}>
							<IconButton Icon={<InfoSVG />} />
						</Tooltip>
					)}

					{!modifiedByManagerAtUtc && !editingDisabled && (
						<>
							<IconButton
								Icon={
									<EditSVG
										onClick={() =>
											setTimeEditModalInfo({
												initialDate,
												initialProject: {
													...project,
													...projectInfo,
												},
											})
										}
									/>
								}
							/>
						</>
					)}
				</div>
			),
			subRows: modifiedByManagerAtUtc
				? [
						{
							name: null,
							userHours: userHours ?? 0,
							date: modifiedByUserAtUtc ? isoToDateString(backendISOUTCToLocalLuxon(modifiedByUserAtUtc).toISO() as string) : null,
							description: description,
							extra: null,
						},
					]
				: undefined,
		};
	});

	// * Modal
	const [timeEditModalInfo, setTimeEditModalInfo] = useState<Omit<TimeEditProps, 'onCancel'> | null>(null);
	const closeTimeEditModal = () => setTimeEditModalInfo(null);

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={!!timeEditModalInfo}
				onClose={closeTimeEditModal}
				width="m"
			>
				{timeEditModalInfo && (
					<div>
						<Heading
							level={2}
							marginBottom="l"
						>
							Редактирование часов
						</Heading>

						<TimeEnter
							userId={userId}
							edit={{
								...timeEditModalInfo,
								onCancel: closeTimeEditModal,
							}}
						/>
					</div>
				)}
			</ModalNewProto>

			{workTimeTableData.length > 0 ? (
				<TableWithComments
					columns={COLUMNS_WORK_TIME}
					data={workTimeTableData}
				/>
			) : (
				<span>Нет данных о внесенных часах</span>
			)}
		</>
	);
};
