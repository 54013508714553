export const getAppEnv = () => {
	const origin = window.location.origin;

	switch (origin) {
		case 'https://dev' || 'http://localhost':
			return 'develop';
		case 'https://preprod':
			return 'preprod';
		case 'https://digital':
			return 'prod';
		default:
			return 'unknown';
	}
};
