import React from 'react';
import { dateToTimeString, isoTimeStringToDate } from 'src/shared/lib/date';
import { Controller, useFormContext } from 'react-hook-form';
import { DateAndTimeRangePickerInput } from '../_inputs/date_pickers/DateAndTimeRangePickerInput';

interface Props {
	dateName: string;
	startTimeName: string;
	endTimeName: string;
	label: string;
	minTime?: Date;
	maxTime?: Date;
	required?: boolean;
}

export const DateAndTimeRangeField: React.FC<Props> = props => {
	const { dateName, startTimeName, endTimeName, label, minTime, maxTime, required } = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={dateName}
			control={control}
			render={({ field: dateField, fieldState: dateFieldState }) => (
				<Controller
					name={startTimeName}
					control={control}
					render={({ field: startField, fieldState: startFieldState }) => (
						<Controller
							name={endTimeName}
							control={control}
							render={({ field: endField, fieldState: endFieldState }) => (
								<DateAndTimeRangePickerInput
									label={label}
									selectedDate={dateField.value}
									setSelectedDate={date => setValue(dateField.name, date)}
									activeStartTime={startField.value ? isoTimeStringToDate(startField.value) : null}
									setActiveStartDate={date => setValue(startField.name, date ? dateToTimeString(date) : null)}
									activeEndDate={endField.value ? isoTimeStringToDate(endField.value) : null}
									setActiveEndDate={date => setValue(endField.name, date ? dateToTimeString(date) : null)}
									errorMessage={dateFieldState.error?.message || startFieldState.error?.message || endFieldState.error?.message}
									minTime={minTime}
									maxTime={maxTime}
									disabledStart={!dateField.value}
									disabledEnd={!dateField.value}
									required={required}
								/>
							)}
						/>
					)}
				/>
			)}
		/>
	);
};
