import { ResponsivePie } from '@nivo/pie';
import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { ComponentPropsWithoutRef } from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { ReactComponent as LeftArrowSVG } from 'src/shared/assets/svg/navigation/Arrow_left_v1.svg';
import { ReactComponent as RightArrowSVG } from 'src/shared/assets/svg/navigation/Arrow_right_v1.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { MonthPickerTitleButton } from 'src/shared/ui/_inputs/date_pickers/MonthPickerTitleButton';
import { CustomLegends } from 'src/features/CustomLegends/CustomLegends';
import { PieDataItem } from 'src/features/TimeChart/_types/types';
import { Tooltip } from 'src/features/TimeChart/ui/Tooltip/Tooltip';
import { getMonthLeaveHours, isDateTheSame } from 'src/shared/lib/date';
import { OTHER_PROJECT_NAME } from '../../../../pages/TimeTrackingPage/consts/consts';
import s from './TimeChart.module.scss';
import { PROJECT_PIE_COLORS } from '../../consts';
import { useWorkTimesInfoContext } from 'src/app/contexts/useWorkTimesInfoContext';

interface Props extends ComponentPropsWithoutRef<'div'> {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	minDate: Date;
	maxDate: Date;
	customEmptyText?: string; // - Для отображения другого текста при отсутствии данных. Используется на главной странице
}

// ! Нужен WorkTimesInfoContextProvider
export const TimeChart: React.FC<Props> = props => {
	const {
		selectedDate, //
		setSelectedDate,
		minDate,
		maxDate,
		customEmptyText,
		className,
	} = props;

	// * Context
	const { workTimeInfo, workTimeMonthLimitInfo, leaveTimesInfo } = useWorkTimesInfoContext();
	const activeLeaveTimeInfo = leaveTimesInfo.filter(leaveTime => leaveTime.leaveTime.isActive);

	// * Selectors
	const rate = useAppSelector(state => state.user_service.user.userInfo?.companyUser?.rate);

	const selectedDateLuxon = DateTime.fromJSDate(selectedDate);

	// * Pie data
	const pieData: PieDataItem[] = [];

	const workTimeData = [...workTimeInfo]
		.filter(({ userHours, managerHours }) => userHours || managerHours)
		.filter(({ project }) => project.name !== null) // ! as string можно использовать далее, так как null отфильтрован.
		.sort((a, b) => (a.project.name as string).localeCompare(b.project.name as string)) // Сортировка проектов по имени (Для стабилизации цветов)
		.map(({ project, managerHours, userHours }, index) => ({
			id: project.name as string,
			value: (managerHours ? managerHours : userHours) ?? 0,
			color: PROJECT_PIE_COLORS[index + 1],
		}));

	pieData.push(...workTimeData);

	const otherWorkTime = workTimeInfo.find(({ project, managerHours, userHours }) => !project.name && (managerHours || userHours));
	if (otherWorkTime) {
		const { managerHours, userHours } = otherWorkTime;
		const otherWorkTimeData = {
			id: OTHER_PROJECT_NAME,
			value: (managerHours ? managerHours : userHours) ?? 0,
			color: 'var(--color-bg-1)',
		};

		pieData.push(otherWorkTimeData);
	}

	const holidays = workTimeMonthLimitInfo[0]?.holidays ?? '1';

	const leaveTimeData = activeLeaveTimeInfo
		.filter(leaveTime => leaveTime.leaveTime.endTime)
		.reduce(
			(sum, object) => ({
				id: sum.id,
				value: sum.value + getMonthLeaveHours(selectedDateLuxon.month, new Date(object.leaveTime.startTime), new Date(object.leaveTime.endTime), holidays, rate),
				color: sum.color,
			}),
			{ id: 'Отсутствия', value: 0, color: 'var(--color-4)' },
		);

	if (leaveTimeData.value > 0) {
		pieData.push(leaveTimeData);
	}

	const normHours = workTimeMonthLimitInfo[0]?.normHours * (rate ?? 1);
	const filledHours = pieData.reduce((sum, object) => sum + object.value, 0);
	const hoursLeft = normHours - pieData.reduce((sum, object) => sum + object.value, 0);

	if (hoursLeft > 0) {
		pieData.push({
			id: 'Запланированные часы' as const,
			value: hoursLeft,
			color: 'var(--color-bg-hover-75)',
		});
	}

	const selectedDateIsMin = isDateTheSame(selectedDate, minDate);
	const selectedDateIsMax = isDateTheSame(selectedDate, maxDate);

	// ! Testing
	// console.log('selectedDate', selectedDate);
	// console.log('minDate', minDate);
	// console.log('maxDate', maxDate);
	// console.log('selectedDateIsMin', selectedDateIsMin);
	// console.log('selectedDateIsMax', selectedDateIsMax);

	const toPreviousMonth = () => {
		if (!selectedDateIsMin) {
			const prevMonth = DateTime.fromJSDate(selectedDate).minus({ month: 1 }).startOf('day').toJSDate();
			setSelectedDate(prevMonth);
		}
	};

	const toNextMonth = () => {
		if (!selectedDateIsMax) {
			const nextMonth = DateTime.fromJSDate(selectedDate).plus({ month: 1 }).startOf('day').toJSDate();
			setSelectedDate(nextMonth);
		}
	};

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<MonthPickerTitleButton
				className={s.datePicker}
				selectedDate={selectedDateLuxon.toJSDate()}
				setSelectedDate={setSelectedDate}
				minDate={minDate}
				maxDate={maxDate}
				prefix="Часы за"
			/>

			<div className={s.chart}>
				<IconButton
					Icon={<LeftArrowSVG />}
					disabled={selectedDateIsMin}
					onClick={toPreviousMonth}
				/>

				<div className={s.chart__wrapper}>
					<ResponsivePie
						data={pieData}
						colors={{
							datum: 'data.color',
						}}
						margin={{ top: 18, right: 18, bottom: 18, left: 18 }}
						innerRadius={0.7}
						startAngle={0}
						padAngle={0}
						cornerRadius={0}
						activeOuterRadiusOffset={6}
						enableArcLabels={false}
						enableArcLinkLabels={false}
						tooltip={({ datum: { data } }) => (
							<Tooltip
								tooltipData={data}
								hoursLeft={hoursLeft}
								leaveTimeInfo={activeLeaveTimeInfo.map(item => item.leaveTime)}
								selectedDateLuxon={selectedDateLuxon}
								holidays={holidays}
							/>
						)}
					/>

					<div className={s.total}>
						{normHours > 0 && (
							<span className={cn(filledHours >= normHours && s.total__fullyFilled)}>
								{filledHours} / {normHours}
							</span>
						)}
					</div>
				</div>

				<IconButton
					Icon={<RightArrowSVG />}
					disabled={selectedDateIsMax}
					onClick={toNextMonth}
				/>
			</div>

			{filledHours === 0 ? <span className={s.noFilled}>{customEmptyText || 'Часы ещё не внесены'}</span> : <CustomLegends legends={pieData} />}
		</div>
	);
};
