import React, { useState } from 'react';
import { useGetImageGetQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { OfficeSchemeMC } from 'src/pages/offices/ui/_pages/SingleOfficePage/OfficeShemeMC/OfficeSchemeMC';
import { useRights } from 'src/shared/hooks/useRights';
import { Button, ButtonVariant } from 'src/shared/ui/_buttons/Button';
import { createGalleyFileFromImageInfo } from 'src/shared/ui/_galleries/Gallery/_utils';
import { MediaModal } from 'src/shared/ui/_modals/MediaModal';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { isButtonVisible } from './lib/isButtonVisible';

interface Props {
	officeId: string;
	buttonVariant?: ButtonVariant;
	imagesOnly?: boolean; // Если true, то будет сразу показываться галерея изображений, без возможности добавления.
}

export const OfficeSchema: React.FC<Props> = props => {
	const { officeId, buttonVariant = 'primary', imagesOnly = false } = props;

	// * Actions
	const { getImage } = imageServiceActions.image.async;

	// * Queries
	const { data: images } = useGetImageGetQuery({ entityId: officeId });

	// * Selectors
	const schemas = (images ?? []).filter(image => image.isSchemaImage);
	const schemasCount = schemas.length;

	const [isOpen, setIsOpen] = useState(false);

	const allowedToEdit = useRights();

	const [imageToShow, setImageToShow] = useState<number | null>(null);

	const onButtonClick = () => {
		if (imagesOnly) {
			setImageToShow(0);
		} else {
			setIsOpen(true);
		}
	};

	const buttonVisible = isButtonVisible(imagesOnly, schemasCount, allowedToEdit);

	// * Render
	return (
		<>
			{isOpen && allowedToEdit && (
				<ModalNewProto
					isOpen={isOpen}
					onClose={() => setIsOpen(!isOpen)}
				>
					<OfficeSchemeMC officeId={officeId} />
				</ModalNewProto>
			)}

			{imageToShow !== null && (
				<MediaModal
					toggleModal={() => setImageToShow(null)}
					files={schemas.map(image => createGalleyFileFromImageInfo(image))}
					imageToShow={imageToShow}
					setImageToShow={setImageToShow}
					fetchImage={(imageId: string) =>
						getImage({
							params: {
								imageId,
								source: 'Office',
							},
						})
					}
					zIndex={5}
				/>
			)}

			{buttonVisible && (
				<Button
					variant={buttonVariant}
					onClick={onButtonClick}
				>
					Схема офиса
				</Button>
			)}
		</>
	);
};
