import React, { ChangeEvent, useState } from 'react';
import { SelectedCount } from 'src/entities/_typography/SelectedCount';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox';
import { Switch } from 'src/shared/ui/_switches/Switch/Switch';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import s from './AddUserList.module.scss';
import { COLUMNS } from './consts';
import { UserListItem } from './types';

interface Props {
	users: UserListItem[];
	selectedUsersIds: string[];
	setSelectedUsers: (selectedUsers: string[]) => void;
}

export const AddUserList: React.FC<Props> = props => {
	const {
		users, //
		selectedUsersIds,
		setSelectedUsers,
	} = props;

	const addNameUser = (selectedUserId: string) => {
		setSelectedUsers([...selectedUsersIds, selectedUserId]);
	};

	const deleteNameUser = (selectedUserId: string) => {
		setSelectedUsers(selectedUsersIds.filter(id => id !== selectedUserId));
	};

	// * Switch
	const [isSelected, setIsSelected] = useState<boolean>(false);

	const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		event.stopPropagation();
		setIsSelected(prevState => !prevState);
	};

	// * Table
	const data = users
		.filter(user => {
			if (!isSelected) return user;
			return selectedUsersIds.find(item => item === user.id);
		})
		.map(user => {
			const checked = selectedUsersIds.includes(user.id);

			return {
				checked,
				avatar: (
					<div className={s.user_checkbox}>
						<Checkbox
							checked={selectedUsersIds.includes(user.id)}
							onChange={() => {
								if (!checked) {
									addNameUser(user.id);
								} else {
									deleteNameUser(user.id);
								}
							}}
						/>

						<Avatar
							name={user.name}
							userAvatar={user.avatar}
						/>
					</div>
				),
				name: (
					<>
						<div className={s.fullName}>{user.name}</div>

						<div className={s.positionName}>{user.position || ''}</div>
					</>
				),
				department: user.department,
			};
		});

	// * Render
	return (
		<>
			<div className={s.selected_block}>
				<SelectedCount
					title="Выбрано сотрудников"
					count={selectedUsersIds.length}
				/>

				<div className={s.selected_switch}>
					<div>Просмотреть выбранных</div>

					<Switch
						checked={isSelected}
						onChange={handleChangeSwitch}
						disabled={selectedUsersIds.length === 0}
					/>
				</div>
			</div>

			<div className={s.table}>
				<CardsTable
					columns={COLUMNS}
					data={data}
				/>
			</div>
		</>
	);
};
