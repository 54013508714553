import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { FindProjectsApiArg } from 'src/app/redux/queries/project-service/types/regTypes';
import { FindResultResponseProjectInfo } from 'src/app/redux/queries/project-service/types/types';
import { RootState } from 'src/app/redux/store';
import { StatusOptions } from 'src/pages/project_service_pages/consts/status';
import { useDebounceEffect } from 'src/pages/project_service_pages/hooks/useDebounceEffect';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { BooleanSwitch } from 'src/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import s from './ProjectFilters.module.scss';
import { SelectSingleWithSearch } from 'src/shared/ui/_inputs/selects/SelectSingleWithSearch';

interface Props {
	findProjects: LazyQueryTrigger<
		QueryDefinition<FindProjectsApiArg, BaseQueryFn, 'Project' | 'File' | 'Image' | 'User' | 'Department', FindResultResponseProjectInfo, 'projectservice'>
	>;
}

export const ProjectFilters: React.FC<Props> = props => {
	const { findProjects } = props;

	// * API
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	// * Selectors
	const userInfo = useSelector((state: RootState) => state.user_service.user.userInfo);

	// * Filters
	// - Deparment
	const departments = departmentData?.body?.map(item => ({
		id: item.id,
		name: item.shortName || item.name,
	})) || [{ id: '', name: '' }];

	const [departmentOption, setDepartmentOption] = useState({ id: '', name: '' });

	// - Status
	const [status, setStatus] = useState({ id: '', name: '' });

	// - Search name
	const [searchValue, setSearchValue] = useState('');

	// - My projects
	const [isSelected, setIsSelected] = useState(true);

	const handleChangeSwitch = () => {
		setIsSelected(prevState => !prevState);
	};

	useDebounceEffect(
		() => {
			const prepared = {};

			if (searchValue) Object.assign(prepared, { nameincludesubstring: searchValue });
			if (isSelected && userInfo?.user.id) Object.assign(prepared, { userid: userInfo.user.id });
			if (status.id) Object.assign(prepared, { projectstatus: status.id });
			if (departmentOption.id) Object.assign(prepared, { departmentid: departmentOption.id });

			findProjects({
				skipCount: 0,
				takeCount: 1000,
				includedepartment: true,
				isascendingsort: true,
				...prepared,
			});
		},
		300,
		[searchValue, isSelected, status, departmentOption],
	);

	// * Render
	return (
		<div className={s.container}>
			<SelectSingleWithSearch
				placeholder="Департамент"
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				selectedOption={departmentOption}
				setSelectedOption={value => setDepartmentOption(value)}
				options={departments}
			/>

			<SelectSingle
				placeholder="Статус"
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				selectedOption={status}
				setSelectedOption={value => setStatus(value)}
				options={StatusOptions}
			/>

			<TextInput
				placeholder="Поиск по названию проекта"
				value={searchValue}
				onChange={event => setSearchValue(event.target.value)}
				RightIcon={<SearchSVG />}
			/>

			<BooleanSwitch
				checked={isSelected}
				text="Мои проекты"
				onChange={handleChangeSwitch}
			/>
		</div>
	);
};
