import { FC } from 'react';
import { usePatchLeaveTimeEditMutation } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { Heading } from 'src/shared/ui/Heading';
import s from './LeaveDeleteModal.module.scss';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Button } from 'src/shared/ui/_buttons/Button';

interface Props {
	leaveDeleteModal: string | null; // Хранит leaveTimeId если не null.
	closeLeaveDeleteModal: () => void;
}

export const LeaveDeleteModal: FC<Props> = props => {
	const {
		leaveDeleteModal, //
		closeLeaveDeleteModal,
	} = props;

	// * API
	const [editLeaveTime, { isLoading }] = usePatchLeaveTimeEditMutation();

	const deleteLeaveTime = (leaveTimeId: string) =>
		editLeaveTime({
			leaveTimeId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: false,
				},
			],
		});

	const onDeleteClick = () => {
		if (leaveDeleteModal) {
			deleteLeaveTime(leaveDeleteModal)
				.then(() => closeLeaveDeleteModal())
				.catch(() => null);
		}
	};

	return (
		<ModalNewProto
			isOpen={!!leaveDeleteModal}
			onClose={closeLeaveDeleteModal}
		>
			<div className={s.container}>
				<Heading level={2}>Удаление записи</Heading>

				<p>Вы действительно хотите удалить запись об отсутствии? Отменить это действие будет невозможно.</p>

				<div className={s.container__buttons}>
					<Button
						onClick={closeLeaveDeleteModal}
						isLoading={isLoading}
						variant="tertiary"
					>
						Отменить
					</Button>

					<Button
						onClick={onDeleteClick}
						isLoading={isLoading}
					>
						Да, удалить
					</Button>
				</div>
			</div>
		</ModalNewProto>
	);
};
